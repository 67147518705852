import { on } from 'delegated-events';
import moveFocus from '../utils/move-focus';

on('click', '.js-collapse', (event) => {
  const { currentTarget: $toggle } = event;

  // Has associated element?
  const contentId = $toggle.getAttribute('aria-controls');
  const $content = contentId ? document.getElementById(contentId) : null;
  if (!$content) {
    return;
  }

  // Prevent other click events
  event.preventDefault();

  // Get current state
  const isOpen = $toggle.getAttribute('aria-expanded') === 'true';

  // Toggle state
  $toggle.setAttribute('aria-expanded', isOpen ? 'false' : 'true');
  $content.hidden = isOpen;

  // Focus on opening
  if (!isOpen) {
    moveFocus($content);
  }
});
