import Swal from 'sweetalert2';

declare global {
  interface HTMLElementEventMap {
    toast: CustomEvent<{
      level: 'success' | 'error';
      message: string;
    }>;
  }
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

document.body.addEventListener('toast', async (event) => {
  const { level, message } = event.detail;

  await Toast.fire({
    icon: level,
    title: message,
  });
});
