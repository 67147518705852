import MicroModal from 'micromodal';
import { on } from 'delegated-events';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import htmx from 'htmx.org';
import whenReady from '../../../javascripts/utils/when-ready';

const enableModal = ($modal) => {
  const $content = $modal.querySelector('.modal__content');

  MicroModal.show($modal.id, {
    onShow() {
      disableBodyScroll($content);
    },
    onClose() {
      enableBodyScroll($content);

      if ($modal.hasAttribute('data-destory-on-close')) {
        $modal.parentNode.removeChild($modal);
      }
    },
  });
};

whenReady().then(() => {
  const $openModal = document.querySelector('.modal[aria-hidden="false"]');

  if ($openModal) {
    enableModal($openModal);
  }
});

on('click', '.js-modal', (event) => {
  const target = event.target.getAttribute('href');
  const $modal = document.querySelector(target);
  event.preventDefault();
  enableModal($modal);
});

on('click', '.modal__close, .modal__background', (event) => {
  const $target = event.target.closest('.modal');
  MicroModal.close($target.id);
});

on('submit', '.modal form', (event) => {
  const $target = event.target.closest('.modal');
  MicroModal.close($target.id);
});

htmx.on('htmx:afterSwap', () => {
  const $openModal = document.querySelector('.modal[aria-hidden="false"]');

  if ($openModal) {
    enableModal($openModal);
  }
});

export default enableModal;
